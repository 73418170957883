import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { Collapse } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import * as S from './TeamCollapse.styles'

const TeamCollapse = () => {
  const [toggleGiorgi, setToggleGiorgi] = useState(false)
  const [toggleMikhail, setToggleMikhail] = useState(false)
  
  return (
    <>
      <S.Button onClick={() => {
        setToggleGiorgi(!toggleGiorgi)
        setToggleMikhail(false)
      }}>
        <Row>
          <Col md={4}><h1>George Bachiashvili</h1></Col>
          <Col md={7}><h1>Founder & CEO</h1></Col>
          <Col md={1} style={{textAlign: 'right'}}>
            <FontAwesomeIcon style={{color: '#C8D3D4'}} icon={!toggleGiorgi ? faPlus : faMinus} />
          </Col>
        </Row>
      </S.Button>
      <Collapse isOpened={toggleGiorgi} initialStyle={{ height: '0', overflow: 'hidden' }}>
        <S.Content>
          <Row between='md'>
            <Col md={3}>
              <S.Photo src='/images/George.jpg' alt='George' />
            </Col>
            <Col md={8}>
              <S.Text>George Bachiashvili is a serial entrepreneur, venture capitalist and early crypto investor who has founded and backed numerous startups in tech, fin-tech, blockchain, and hemp industries. In 2014 he established Mission Gate – a venture capital firm investing globally in angel and A/B stage projects.</S.Text>
              <S.Text>Previously, he has founded the Georgian Co-Investment Fund which he managed for six years. GCF is the largest PE fund based in Georgia, focusing primarily on investing in Energy, Hospitality, Agriculture and Manufacturing sectors. At GCF, he currently holds the position of the Head of the Advisory Committee.</S.Text>
              <S.Text>2014-2022 George also served as the Chairman of the Supervisory Board of the Georgian Stock Exchange, where he was actively involved in the corporate turnaround process.</S.Text>
              <S.Text>In 2014 he was named 3 rd in the Forbes global “30 Under 30: Finance” list. George is also a member of the Investment Committee at GITA’s - Georgia Start-up Grants program, where he identifies and selects promising startups and entrepreneurs to provide assistance and grants of various sizes. Together with INSEAD, George published a case study: “Private Equity in Frontier Markets: Creating a Fund in Georgia” where he shares his experiences of establishing and managing a PE fund.</S.Text>
              <S.Text>Before getting into startups, George accumulated a broad range of experience in management and finance while serving at various positions at Booz &amp; Co, Abu Dhabi Group and Bank of Georgia. George holds a BBA Degree in Finance from Caucasus University and an MBA from INSEAD.</S.Text>
            </Col>
          </Row>
        </S.Content>
      </Collapse>
      <S.Button onClick={() => {
        setToggleMikhail(!toggleMikhail)
        setToggleGiorgi(false)
      }}>

        <Row>
          <Col md={4}><h1>Mikhail Ninikelashvili</h1></Col>
          <Col md={7}><h1>Managing Director</h1></Col>
          <Col md={1} style={{textAlign: 'right'}}>
            <FontAwesomeIcon style={{color: '#C8D3D4'}} icon={!toggleMikhail ? faPlus : faMinus} />
          </Col>
        </Row>
      </S.Button>
      <Collapse isOpened={toggleMikhail} initialStyle={{ height: '0', overflow: 'hidden' }}>
        <S.Content>
          <Row between='md'>
            <Col md={3}>
              <S.Photo src='/images/Misha1.jpg' alt='Mikhail' />
            </Col>
            <Col md={8}>
              <S.Text>Mikhail is an experienced finance professional with more than 13 years of experience in commercial banking.</S.Text>
              <S.Text>Mikhail joined Bank of Georgia’s Corporate Banking team as an analyst in 2008, where he covered the largest corporate clients of the bank. In 2016 he moved to TBC Bank’s Corporate and Investment Banking Department as a Sector Head, at different times being in charge of oil and gas, heavy industry, transportation and telecommunications sectors.</S.Text>
              <S.Text>Since 2019 he was a Senior Investment Banker in TBC Capital, Investment Banking arm of TBC Bank, executing number of high-profile Debt Capital Market transactions both locally and internationally (in the form of Eurobond issues from Georgia).</S.Text>
              <S.Text>Since September 2021 he is the part of Mission Gate team.</S.Text>
              <S.Text>Mikhail holds a BBA degree from Caucasus University with major in Finance.</S.Text>
            </Col>
          </Row>
        </S.Content>
      </Collapse>
    </>
  )
}

export default TeamCollapse
